<template>
  <div>
    <div class="card mb-3" v-for="membership in memberships" :key="membership.id">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h2>{{ membership.name }}</h2>
            <p>{{ membership.description }}</p>
          </div>
          <div class="col">
            <div class="price">
              Price:
              <strong>
                <span v-if="membership.currency.prefix">{{ membership.currency.prefix }} </span>
                {{ membership.price }}
                <span v-if="membership.currency.decimal">.00</span>
                <span v-if="membership.currency.suffix">{{ membership.currency.suffix }}</span>
              </strong>
            </div>
            <div class="number_of_months">
              Renewal every <strong>{{ membership.number_of_months }} month{{ membership.number_of_months !== 1 ? "s" : "" }}</strong>
            </div>
          </div>
        </div>
        <button class="btn btn-success btn-block" @click="createSubscription(membership.id)">Start membership</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      memberships: {},
    };
  },
  async mounted() {
    const { data } = await this.$http.get("/memberships");
    this.memberships = data;
  },
  methods: {
    async createSubscription(membership_id) {
      await this.$http.post("/subscription", { membership_id: membership_id });
      this.$router.push("/");
    },
  },
};
</script>